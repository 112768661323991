import React from 'react';
import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Pureradio.one</h1>
      </header>
      <div className="App-content">
        <img src={logo} alt="logo" className="App-logo" />
      </div>
      <footer className="App-footer">
        <audio className="App-player" id="Player" src="https://pureradio.one:8010/" controls="controls" />
      </footer>
    </div>
  );
}

export default App;
